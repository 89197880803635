var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("Share settings"),
            persistent: "",
            "max-width": 1000,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [_vm._t("activator", null, null, { on, attrs })]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        "cs-simple-dialog",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "cs-flex",
        {
          staticClass: "save-as",
          attrs: {
            "fill-height": "",
            "fill-width": "",
            align: "center",
            vertical: "",
            justify: "flex-start",
            "no-content-stretch": "",
          },
        },
        [
          _c(
            "cs-grid",
            {
              staticClass: "main-grid pl-5 pr-5 mb-1",
              attrs: {
                "template-columns": "1fr",
                gap: `calc(1rem/2)`,
                justify: "stretch",
                "fill-width": "",
              },
            },
            [
              _c(
                "cs-grid",
                {
                  staticClass: "text-subtitle-2 pb-1",
                  attrs: {
                    "template-columns": "2.5rem 1.3fr repeat(2, 1fr) 1rem",
                    "fill-width": "",
                    justify: "stretch",
                    gap: "1rem",
                  },
                },
                [
                  _c("div"),
                  _c("div"),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Type of access")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Content")))]),
                  _c("div"),
                ]
              ),
              _c(
                "cs-grid",
                {
                  staticClass: "text-center",
                  attrs: {
                    "template-columns": "2.5rem 1.3fr repeat(2, 1fr) 2.5rem",
                    "fill-width": "",
                    justify: "stretch",
                    gap: "1rem",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-account-circle-outline"),
                      ]),
                    ],
                    1
                  ),
                  _c("cs-text-field", {
                    attrs: {
                      dense: "",
                      flat: "",
                      outlined: "",
                      "hide-details": "",
                      placeholder: _vm.$t("Email"),
                      autofocus: "",
                      error: _vm.isInList,
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        !_vm.isInList && !_vm.isEmpty ? _vm.addShare() : null
                      },
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("cs-select", {
                    staticClass: "font-weight-medium",
                    attrs: {
                      solo: "",
                      flat: "",
                      "hide-details": "",
                      "append-icon": "mdi-chevron-down",
                      "menu-props": { bottom: true, offsetY: true },
                      items: _vm.platforms,
                      "item-text": "displayText",
                      "item-value": "value",
                      "return-object": "",
                      dense: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.sharePlatform,
                      callback: function ($$v) {
                        _vm.sharePlatform = $$v
                      },
                      expression: "sharePlatform",
                    },
                  }),
                  _c("cs-select", {
                    staticClass: "font-weight-medium",
                    attrs: {
                      solo: "",
                      outlined: "",
                      flat: "",
                      dense: "",
                      "hide-details": "",
                      "append-icon": "mdi-chevron-down",
                      "menu-props": { bottom: true, offsetY: true },
                      items: _vm.types,
                      "item-text": "displayText",
                      "item-value": "value",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.shareType,
                      callback: function ($$v) {
                        _vm.shareType = $$v
                      },
                      expression: "shareType",
                    },
                  }),
                  _c(
                    "cs-btn",
                    {
                      staticClass: "shareBtn",
                      attrs: {
                        fab: "",
                        text: "",
                        small: "",
                        disabled: _vm.isInList || _vm.isEmpty,
                        loading: _vm.addShareLoading,
                      },
                      on: { click: _vm.addShare },
                    },
                    [_c("v-icon", [_vm._v("mdi-share-variant")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "cs-grid",
                {
                  staticClass: "pb-3",
                  attrs: { "template-columns": "1fr", gap: `calc(1rem / 2)` },
                },
                _vm._l(_vm.shares, function (share) {
                  return _c("share-user-row", {
                    key: share.user.email,
                    attrs: {
                      share: share,
                      currentlyLoading: _vm.currentlyLoading,
                      templateColumns: "2.5rem 1.3fr repeat(2, 1fr) 2.5rem",
                    },
                    on: { remove: _vm.removeShare, modify: _vm.modifyShare },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }