
import ShareUserRow from '@/components/ShareUserRow.vue'
import {
  addShare,
  modifyShare,
  removeShare,
  SharePlatform,
  ShareType,
  SteelspaceModel,
  SteelspaceModelUserShare,
} from '@/modules/model'
import { CsBtn, CsFlex, CsGrid, CsSelect, CsTextField, CsSimpleDialog } from '@consteel/csuetify'
import { authStore } from '@/modules/auth'
import Vue from 'vue'

export default Vue.extend({
  name: 'ShareDialog',
  components: {
    CsSimpleDialog,
    CsFlex,
    CsGrid,
    CsTextField,
    CsSelect,
    ShareUserRow,
    CsBtn,
  },
  props: {
    value: Boolean,
    shares: {
      type: Array as () => Array<SteelspaceModelUserShare>,
    },
    selectedModel: {
      type: Object as () => SteelspaceModel,
    },
  },

  data() {
    return {
      platforms: [
        {
          displayText: this.$t('Viewer'),
          value: SharePlatform.Steelspace,
        },
        {
          displayText: this.$t('Collaborator'),
          value: SharePlatform.SteelspaceAndDesktop,
        },
      ],
      sharePlatform: {
        displayText: this.$t('Collaborator'),
        value: SharePlatform.SteelspaceAndDesktop,
      },
      shareType: {
        displayText: this.$t('Model and results'),
        value: ShareType.ModelAndResult,
      },
      types: [
        { displayText: this.$t('Model only'), value: ShareType.Model },
        {
          displayText: this.$t('Model and results'),
          value: ShareType.ModelAndResult,
        },
      ],
      email: '',
      error: false,
      currentlyLoading: '',
      addShareLoading: false,
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value
      },
      set(newVal: boolean): void {
        this.$emit('input', newVal)
      },
    },
    currentUser() {
      return authStore.currentSteelspaceUser
    },
    isInList(): boolean {
      if (
        this.shares.find((share) => share.user.email === this.email) ||
        this.email === this.currentUser?.email
      ) {
        return true
      } else {
        return false
      }
    },
    isEmpty(): boolean {
      if (this.email.length === 0) {
        return true
      } else {
        return false
      }
    },
    isEmail(): boolean {
      return true
    },
  },
  methods: {
    async addShare() {
      this.addShareLoading = true
      const share = new SteelspaceModelUserShare()
      share.user.email = this.email
      share.SetShare(this.sharePlatform.value, this.shareType.value)
      this.email = ''
      this.currentlyLoading = share.user.email
      await addShare(this.selectedModel.id, share)
      this.currentlyLoading = ''
      this.addShareLoading = false
    },
    async modifyShare(share: SteelspaceModelUserShare) {
      this.currentlyLoading = share.user.email
      await modifyShare(this.selectedModel.id, share)
      this.currentlyLoading = ''
    },
    async removeShare(email: string) {
      const toDelete = this.shares.find((share) => share.user.email === email)
      this.currentlyLoading = email
      if (toDelete) {
        await removeShare(this.selectedModel.id, toDelete)
      }
      this.currentlyLoading = ''
    },
  },
})
