
// TODO: Refaktorálni kéne a SteelspaceModelUserShare osztályt, antipattern, hogy propként kapott JS osztály függvényeit hívjuk

import { SharePlatform, ShareType, SteelspaceModelUserShare } from '@/modules/model'
import { CsAvatar, CsBtn, CsSelect } from '@consteel/csuetify'
import CsGrid from '@consteel/csuetify/src/components/CsContainers/CsGrid.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'ShareUserRow',
  components: {
    CsSelect,
    CsBtn,
    CsGrid,
    CsAvatar,
  },
  props: {
    share: {
      required: true,
      type: SteelspaceModelUserShare,
    },
    templateColumns: {
      required: true,
      type: String,
    },
    columnGap: {
      required: false,
      type: String,
      default: '1rem',
    },
    currentlyLoading: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      platforms: [
        {
          displayText: this.$t('Viewer'),
          value: SharePlatform.Steelspace,
        },
        {
          displayText: this.$t('Collaborator'),
          value: SharePlatform.SteelspaceAndDesktop,
        },
      ],
      types: [
        { displayText: this.$t('Model only'), value: ShareType.Model },
        {
          displayText: this.$t('Model and results'),
          value: ShareType.ModelAndResult,
        },
      ],
      sharePlatform: SharePlatform.Steelspace,
      shareType: ShareType.Model,
    }
  },
  computed: {
    changed(): boolean {
      if (
        this.sharePlatform === this.share.GetSharePlatform() &&
        this.shareType === this.share.GetShareType()
      ) {
        return false
      } else {
        return true
      }
    },
    isThisLoading(): boolean {
      return this.currentlyLoading === this.share.user.email
    },
  },
  methods: {
    onModify() {
      const modifiedShare = new SteelspaceModelUserShare({ ...this.share })
      modifiedShare.SetShare(this.sharePlatform, this.shareType)
      this.$emit('modify', modifiedShare)
    },
    onDelete() {
      this.$emit('remove', this.share.user.email)
    },
  },
  mounted() {
    this.sharePlatform = this.share.GetSharePlatform()
    this.shareType = this.share.GetShareType()
  },
})
